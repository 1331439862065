<template>

    <auth-content>
      <el-header :title="$t('modules.wiki.onboarding.title')" slot="header" permissions="wiki">
        <div slot="actions">
          <el-header-action v-if="isCurrentWorldOwner" :disabled="loading" size="xs" icon="add" :label="$t('modules.wiki.onboarding.button_text_add')" @click.native="openCreateModal()"></el-header-action>
        </div>
      </el-header>

      <el-filters :filters="filters" v-if="Object.keys(content).length > 1"/>

      <div v-if="Object.keys(content).length > 0" class="row">

        <div v-for="onboarding in contentFiltered" class="col-xxl-4 col-lg-6 col-xs-12 mb-3">
          <div
            class="card onboarding-card mb-0"
            :class="{'cursor-pointer': !worldCanManageTemplates}"
            @click="onboardingPencilHovered || worldCanManageTemplates ? false : openOnboarding(onboarding)"
          >
            <div class="onboarding-name card-header py-3" :style="'border-color: ' + onboarding.data.color + ';'">
              <i
                class="icon-pencil float-right cursor-pointer text-grey py-1"
                v-if="onboarding.world_id != null && isCurrentWorldOwner"
                @click="openEditModal(onboarding)"
                @mouseenter="onboardingPencilHovered = true"
                @mouseleave="onboardingPencilHovered = false"
              />
              <small class="text-uppercase font-weight-bold" :style="'color: ' + onboarding.data.color + ';'">{{ onboarding.data.category }}</small>
              <small class="badge badge-dark ml-2" v-if="onboarding.template">Template</small>
              <h3 class="m-0">{{ onboarding.data.name }}</h3>
            </div>
            <div class="card-body py-3">
              <p class="text-grey">
                {{ $tc('modules.wiki.onboarding.nb_lessons', onboarding.data.content.length) }}

                <span
                  v-for="(slide, slideIndex) in onboarding.data.content"
                >
                  <i
                    :class="{
                      'ml-1': true,
                      'icon-circle2': !isCompleted(slide),
                      'icon-checkmark-circle': isCompleted(slide),
                      'text-grey': slideIndex != currentOnboardingStep && !isCompleted(slide),
                      'text-success': isCompleted(slide),
                    }"></i>
                </span>


              </p>
              <span v-if="onboarding.data && onboarding.data.description">
                {{ onboarding.data.description }}
              </span>
            </div>
          </div>
        </div>

      </div>
      <el-empty v-else :title="$t('modules.wiki.onboarding.empty_title')" :text="$t('modules.wiki.onboarding.empty_text')"></el-empty>

      <el-modal
        size="fullwidth"
        :title="currentOnboardingTitle"
        :subtitle="currentOnboardingSubtitle"
        :color="currentOnboardingColor"
        id="wiki-onboarding-view-modal"
      >
        <div class="text-center" v-if="!currentOnboarding || !currentOnboardingLoaded">
          <loader />
        </div>
        <div class="row" v-else>
          <div class="col-md-3 bg-light">
            <div class="px-2 py-3 summary position-sticky sticky-top pt-5">
              <div
                class="h5 pt-2 cursor-pointer"
                v-for="(slide, slideIndex) in currentOnboarding.data.content_detail"
                :style="slideIndex == currentOnboardingStep ? 'color: ' + currentOnboarding.data.color + ';' : ''"
                @click="currentOnboardingStep = slideIndex"
              >
                <i
                  :class="{
                    'mr-1': true,
                    'icon-circle2': !isCompleted(slide),
                    'icon-checkmark-circle': isCompleted(slide),
                    'text-grey': slideIndex != currentOnboardingStep && !isCompleted(slide),
                    'text-success': isCompleted(slide),
                  }"></i>
                <span>{{ slide.name }}</span>
              </div>
            </div>
          </div>
          <div class="col-md-9">

            <div v-if="!currentOnboardingLoaded" class="text-center py-4">
              <loader />
            </div>
            <div v-else-if="currentOnboardingSlide">
              <div class="clearfix p-3">
                <div class="text-right" v-if="isCurrentWorldOwner">
                  <a :href="$router.resolve({name: 'wiki.content', params: {world: $route.params.world, content: currentOnboardingSlide.id}}).href" target="_new" class="btn btn-sm btn-grey text-grey">
                    <i
                      class="icon-new-tab text-grey ml-1 cursor-pointer"
                    />
                    {{ $t('modules.wiki.onboarding.edit_wiki_content') }}
                  </a>
                </div>
                <div class="my-3 text-center">
                  <img v-if="currentOnboardingSlide.category" class="cat-picto" :src="require('@/assets/images/categories/' + currentOnboardingSlide.category.name + '.svg')" @error="defaultCatImage">
                  <div v-if="currentOnboardingSlide.category" class="small pt-2 text-grey font-weight-bold text-uppercase">{{ $t('modules.quest.categories.' + currentOnboardingSlide.category.name) }}</div>
                  <h1 :style="'color: ' + currentOnboarding.data.color + ';'">{{ currentOnboardingSlide.name }}</h1>
                </div>
                <div class="editor">
                  <div v-if="currentOnboardingSlide.content" v-html="currentOnboardingSlide.content" class="editor__content"/>
                </div>
                <div class="text-center">
                  <div
                    v-if="!isCompleted(currentOnboardingSlide)"
                    @click="completeOnboarding(currentOnboardingSlide.id)"
                    class="m-0 py-3 w-100 h5 bg-success text-white rounded cursor-pointer"
                  >
                    <div v-if="!completeOnboardingLoading">
                      {{ $t('modules.wiki.onboarding.lesson_complete') }}
                      <i class="ml-2 icon-checkmark-circle"></i>
                    </div>
                    <div v-else>
                      <loader />
                    </div>
                  </div>
                  <div v-else class="py-3 text-center h5 text-success">
                      {{ $t('modules.wiki.onboarding.lesson_completed') }}
                      <i class="ml-2 icon-checkmark-circle"></i>
                  </div>
                  <div class="row mt-3">
                    <div class="col text-left">
                      <div v-if="currentOnboardingStep > 0" @click="goPrevLesson()" class="btn btn-outline-secondary">
                        <i class="icon-arrow-left8"></i>
                        <span class='pl-1 d-none d-md-inline-block'>{{ $t('modules.wiki.onboarding.lesson_previous') }}</span>
                      </div>
                    </div>
                    <div class="col text-right">
                      <div v-if="currentOnboardingStep < currentOnboarding.data.content_detail.length - 1" @click="goNextLesson()" class="btn btn-outline-secondary">
                        <span class='pr-1 d-none d-md-inline-block'>{{ $t('modules.wiki.onboarding.lesson_next') }}</span>
                        <i class="icon-arrow-right8"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </el-modal>


      <el-modal
        size="fullwidth"
        :title="onboardingModalEdit && onboardingModalEdit.id ? $t(`common.edit`) : $t(`modules.wiki.onboarding.button_text_add`)"
        id="wiki-onboarding-edit-modal"
      >
        <div v-if="onboardingModalEdit && onboardingModalEdit.world_id != null && isCurrentWorldOwner">
          <div class="container">
            <div class="row">
              <div class="col-lg-6">

                <label class="mt-2 font-weight-bold" for="name">{{ $t('modules.wiki.onboarding.fields.name') }}</label>
                <input
                  v-model="onboardingModalEdit.data.name"
                  :required="true"
                  type="text"
                  class="form-control"
                  id="name"
                  :placeholder="$t('modules.wiki.onboarding.fields.name')"
                />
                <label class="mt-2 font-weight-bold" for="name">{{ $t('modules.wiki.onboarding.fields.category') }}</label>
                <input
                  v-model="onboardingModalEdit.data.category"
                  :required="true"
                  type="text"
                  class="form-control"
                  id="category"
                  :placeholder="$t('modules.wiki.onboarding.fields.category')"
                />
                <label class="mt-2 font-weight-bold" for="name">{{ $t('modules.wiki.onboarding.fields.color') }}</label>
                <div>
                  <span v-for="color in colors">
                    <label>
                      <input
                        v-model="onboardingModalEdit.data.color"
                        :required="true"
                        :value="color"
                        type="radio"
                        id="color"
                        class="d-none"
                        :placeholder="$t('modules.wiki.onboarding.fields.color')"
                      />
                      <div class="colorfield" :class="onboardingModalEdit.data.color == color ? 'active' : ''" :style="'background: ' + color + ';'"></div>
                    </label>
                  </span>
                </div>
                <label class="mt-2 font-weight-bold" for="name">{{ $t('modules.wiki.onboarding.fields.description') }}</label>
                <textarea
                  v-model="onboardingModalEdit.data.description"
                  :required="true"
                  type="text"
                  class="form-control"
                  id="description"
                  :placeholder="$t('modules.wiki.onboarding.fields.description')"
                />

              </div>
              <div class="col-lg-6">

                <label class="mt-2 font-weight-bold" for="name">{{ $t('modules.wiki.onboarding.fields.content') }}</label>

                <draggable class="" :list="onboardingModalEdit.data.content" group="content" handle=".handle">
                  <div v-for="(contentData, contentIndex) in onboardingModalEdit.data.content" class="py-1 my-1 d-flex align-items-center justify-content-between">
                    <div>
                      <i class="handle icon-menu7 small text-grey mr-1 cursor-move" />
                      <span>
                        <a v-if="contentData.type == 'wiki' && wikiContentByKey[contentData.id]" :href="$router.resolve({name: 'wiki.content', params: {world: $route.params.world, content: contentData.id}}).href" target="_new">
                          <i
                            class="icon-new-tab text-grey ml-1 cursor-pointer"
                          />
                        </a>
                        <span v-if="contentData.type == 'wiki' && wikiContentByKey[contentData.id]">
                          {{ wikiContentByKey[contentData.id].name }}
                          <span class="badge badge-light" v-if="wikiContentByKey[contentData.id].category">{{ getCategoryTitle(wikiContentByKey[contentData.id].category.name) }}</span>
                        </span>
                        <span v-else-if="contentData.type == 'quest' && questSheets[contentData.id]">
                          {{ questSheets[contentData.id].name }}
                          <span class="badge badge-light" v-if="questSheets[contentData.id].category">
                            {{ getCategoryTitle(questSheets[contentData.id].category) }}
                            |
                            Level {{ questSheets[contentData.id].level }}
                          </span>
                        </span>
                        <span v-else>
                          <i class="text-grey text-italic">Deleted content</i>
                        </span>
                      </span>
                    </div>
                    <i
                      class="icon-cross3 text-danger ml-1 cursor-pointer"
                      @click="deleteContentFromOnboarding(contentIndex)"
                    />
                  </div>
                </draggable>

                <el-select
                  v-if="!worldCanManageTemplates"
                  :required="true"
                  :options="wikiContentOptions"
                  :placeholder="$t('modules.wiki.onboarding.fields.content_wiki_add')"
                  :callback="addWikiContentToOnboarding"
                />

                <el-select
                  v-if="worldCanManageTemplates"
                  :required="true"
                  :options="questSheetsOptions"
                  :placeholder="$t('modules.wiki.onboarding.fields.content_quest_add')"
                  :callback="addQuestSheetToOnboarding"
                />

              </div>
            </div>

            <div class="text-center col-12 mt-3">
              <div v-if="!onboardingModalEditLoading && onboardingModalEdit.id" @click.prevent="submitDataForm" class="btn btn-success text-white">{{ $t('common.edit') }}</div>
              <div v-if="!onboardingModalEditLoading && !onboardingModalEdit.id" @click.prevent="submitDataForm" class="btn btn-success text-white">{{ $t('common.validate') }}</div>
              <div v-if="onboardingModalEditLoading" class="text-gray pt-1 pb-2"><loader /></div>
              <div>
                <div
                  v-if="!onboardingModalEditLoading && onboardingModalEdit.id"
                  class="btn btn-link btn-sm text-danger"
                  @dblclick="deleteOnboarding"
                >
                  {{ $t('common.dbl_click_delete') }}
                </div>
              </div>
            </div>

          </div>

        </div>

      </el-modal>

    </auth-content>

</template>

<script>

import draggable from 'vuedraggable'
import Fuse from 'fuse.js'
import _ from 'lodash'
import store from '@/store'

import {
  WIKI_GET_CONTENTS,
  WIKI_GET_ONBOARDINGS,
  WIKI_GET_ONBOARDING,
  WIKI_CREATE_ONBOARDING,
  WIKI_EDIT_ONBOARDING,
  WIKI_COMPLETE_ONBOARDING,
  WIKI_GET_COMPLETED_ONBOARDINGS,
  WIKI_DELETE_ONBOARDING,
  WIKI_ONBOARDING_GET_SHEETS,
} from '../mutations-types'

export default {
  name: 'WikiOnboardingIndex',
  components: {
    draggable,
  },
  data () {
    return {
      colors: ['#4D4D4D','#999999','#F44E3B','#FE9200','#FCDC00','#DBDF00','#A4DD00','#68CCCA','#73D8FF','#AEA1FF','#FDA1FF','#333333','#808080','#CCCCCC','#D33115','#E27300','#FCC400','#B0BC00','#68BC00','#16A5A5','#009CE0','#7B64FF','#FA28FF','#000000','#666666','#B3B3B3','#9F0500','#C45100','#FB9E00','#808900','#194D33','#0C797D','#0062B1','#653294','#AB149E'],
      onboardingModalEdit: null,
      onboardingModalEditLoading: false,
      onboardingPencilHovered: false,
      wikiContent: null,
      questSheets: null,
      completedOnboardings: null,
      currentOnboardingLoaded: false,
      currentOnboarding: null,
      currentOnboardingStep: 0,
      completeOnboardingLoading: false,
      loading: false,
      fields: false,
      content: false,
      columns: {
        name: this.$t('modules.wiki.onboarding.fields.name'),
      },
      filters: false
    }
  },
  mounted () {
    this.changeSidebarRight(!this.areFiltersClean)
    this.$store.dispatch('wiki/' + WIKI_GET_CONTENTS, this.$route.params.world).then(wikiContent => {
      this.wikiContent = {...wikiContent}
    })
    this.$store.dispatch('wiki/' + WIKI_GET_COMPLETED_ONBOARDINGS, this.$route.params.world).then(completedOnboardings => {
      this.completedOnboardings = {...completedOnboardings}
    })

    if (this.worldCanManageTemplates) {
      this.$store.dispatch('wiki/' + WIKI_ONBOARDING_GET_SHEETS, this.$route.params.world).then(questSheets => {
        this.questSheets = _.keyBy({...questSheets}, 'id')
      })
    }

  },
  methods: {
    deleteContentFromOnboarding (index) {
      this.onboardingModalEdit.data.content.splice(index, 1)
    },
    addWikiContentToOnboarding (cid) {
      this.onboardingModalEdit.data.content.push({
        'id': cid,
        'type': 'wiki',
      })
    },
    addQuestSheetToOnboarding (cid) {
      this.onboardingModalEdit.data.content.push({
        'id': cid,
        'type': 'quest',
      })
    },
    openEditModal (onboarding) {
      this.onboardingModalEdit = _.cloneDeep(onboarding)
      this.openModal('wiki-onboarding-edit-modal')
    },
    openCreateModal (onboarding) {
      this.onboardingModalEdit = {
        world_id: this.$route.params.world,
        data: {
          color: '',
          category: '',
          description: '',
          name: '',
          content: [],
        },
      }
      this.openModal('wiki-onboarding-edit-modal')
    },
    deleteOnboarding () {
      this.onboardingModalEditLoading = true
      this.$store.dispatch('wiki/' + WIKI_DELETE_ONBOARDING, {
        world_id: this.$route.params.world,
        id: this.onboardingModalEdit.id,
      }).then(() => {
        this.onboardingModalEditLoading = false
        this.notifSuccess(this.$t('modules.wiki.onboarding.messages.delete_success'))
        let foundIndex = Object.keys(this.content).findIndex(elem => this.content[elem].id == this.onboardingModalEdit.id)
        this.closeModal('wiki-onboarding-edit-modal')
        this.$delete(this.content, foundIndex)
        this.loadFilters()
      })
      .catch(error => {
        this.onboardingModalEditLoading = false
        this.notifError(error)
      })
    },
    submitDataForm () {
      this.onboardingModalEditLoading = true
      let action = 'wiki/' + WIKI_CREATE_ONBOARDING
      if (this.onboardingModalEdit.id) {
        action = 'wiki/' + WIKI_EDIT_ONBOARDING
      }

      let dataToSend = _.cloneDeep(this.onboardingModalEdit)
      delete dataToSend.world

      this.$store.dispatch(action, dataToSend).then(data => {
        this.onboardingModalEditLoading = false
        if (this.onboardingModalEdit.id) {
          this.notifSuccess(this.$t(`modules.wiki.onboarding.messages.edit_success`))
          let foundIndex = Object.keys(this.content).findIndex(elem => this.content[elem].id == data.data.id)
          this.$set(this.content, foundIndex, data.data);
        } else {
          this.$set(this.content, Object.keys(this.content).length, data.data)
          this.loadFilters()
          this.notifSuccess(this.$t(`modules.wiki.onboarding.messages.add_success`))
        }
        this.loadFilters()
        this.closeModal('wiki-onboarding-edit-modal')
      })
      .catch(error => {
        this.onboardingModalEditLoading = false
        this.notifError(error)
      })
    },
    goPrevLesson() {
      this.currentOnboardingStep--
      document.getElementById('wiki-onboarding-view-modal').scrollTo(0,0)
    },
    goNextLesson() {
      this.currentOnboardingStep++
      document.getElementById('wiki-onboarding-view-modal').scrollTo(0,0)
    },
    openOnboarding(onboarding) {
      this.currentOnboardingLoaded = false
      this.currentOnboarding = onboarding
      this.openModal('wiki-onboarding-view-modal')

      this.$store.dispatch('wiki/' + WIKI_GET_ONBOARDING, {
        world_id: this.$route.params.world,
        id: this.currentOnboarding.id,
      }).then(onboarding => {
        this.currentOnboarding = onboarding
        this.currentOnboardingStep = 0
        this.currentOnboarding.data.content.every((elem, index) => {
          if (this.isCompleted(elem)) {
            this.currentOnboardingStep = index + 1;
            return true
          } else {
            return false
          }
        })
        if (this.currentOnboardingStep >= this.currentOnboarding.data.content.length) {
          this.currentOnboardingStep = 0
        }
        this.currentOnboardingLoaded = true
      })
    },
    completeOnboarding(contentId) {
      if (this.completeOnboardingLoading) {
        return
      }

      this.completeOnboardingLoading = true
      this.$store.dispatch('wiki/' + WIKI_COMPLETE_ONBOARDING, {
        world_id: this.$route.params.world,
        id: this.currentOnboardingSlide.id,
      }).then(response => {
        this.completedOnboardings = response
        this.completeOnboardingLoading = false
      })
    },
    isCompleted(content) {
      if (!this.completedOnboardings) {
        return false
      }
      if(this.completedOnboardings.data && this.completedOnboardings.data.completed && this.completedOnboardings.data.completed[content.id] && content.type == "wiki") {
        return true
      }
      return false
    },
    changeSidebarRight(val = null) {
      if (val === null) {
        val = !this.isSidebarRightOpened
      }
      this.$store.commit('global/changeSidebarRight', val)
    },
    defaultCatImage (event) {
      event.target.src = require('@/assets/images/mascot-circle.svg')
    },
    cleanFilters () {
      _.forEach(this.filters, filter => {
        _.forEach(filter.options, option => {
          option.value = false
        })
      })
    },
    loadFilters () {
      let content = {...this.formatContent()}
      this.filters = Object.assign({}, this.filters, [
        {
          title: this.$t('modules.wiki.onboarding.fields.category'),
          key: 'filter_category',
          options: this.getFilterOptions(content, 'filter_category'),
        }
      ])
    },
    formatContent () {
      let contentArray = []
      if (this.content) {
        // Get content
        _.map(this.content, (elem) => {
          let content = elem
          content.filter_category = content.data.category
          contentArray.push(content);
        })
      }
      return contentArray
    },
    getCategoryTitle (categoryLabel) {
      return this.$te('modules.quest.categories.' + categoryLabel) ? this.$t('modules.quest.categories.' + categoryLabel) : categoryLabel
    }
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('wiki/' + WIKI_GET_ONBOARDINGS, to.params.world).then(content => {
        next(vm => {
          vm.content = {...content}
          vm.loadFilters()
        })
    })
  },
  computed: {
    questSheetsOptions () {
      if (!this.questSheets) {
        return []
      } else {
        return [{
                    title: this.$t('modules.academy.course.form.content.placeholder'),
                    value: '',
               }].concat(_.map(this.questSheets, content => {
                  let detail = this.getCategoryTitle(content.category)
                  if (content.level) {
                    detail+= ' | Level ' + content.level
                  }
                  return {
                    title: content.name,
                    value: content.id,
                    category: content.category,
                    detail: detail,
                  }
                }))
      }
    },
    worldCanManageTemplates () {
      return this.$store.getters['auth/worldCanManageTemplates'];
    },
    wikiContentOptions () {
      if (!this.wikiContent) {
        return []
      } else {
        return [{
                    title: this.$t('modules.wiki.onboarding.fields.content'),
                    value: '',
               }].concat(_.map(this.wikiContent, content => {
                  return {
                    title: content.name,
                    value: content.id,
                    detail: content.category ? this.getCategoryTitle(content.category.name) : null,
                  }
                }))
      }
    },
    wikiContentByKey () {
      return _.keyBy(_.cloneDeep(this.wikiContent), 'id')
    },
    currentOnboardingTitle () {
      if (this.currentOnboarding) {
        return this.currentOnboarding.data.name
      }
      return '';
    },
    currentOnboardingSubtitle () {
      if (this.currentOnboarding) {
        return this.currentOnboarding.data.category
      }
      return '';
    },
    currentOnboardingColor () {
      if (this.currentOnboarding) {
        return this.currentOnboarding.data.color
      }
      return '';
    },
    currentOnboardingSlide () {
      if (this.currentOnboarding) {
        return this.currentOnboarding.data.content_detail[this.currentOnboardingStep]
      }
      return null
    },
    currentOnboardingSlideIsLast () {
      if (this.currentOnboardingSlide) {
        return this.currentOnboardingSlide >= this.currentOnboarding.data.content_detail - 1
      }
      return false
    },
    currentUser () {
      return this.$store.getters['auth/getProfile']
    },
    areFiltersClean () {
      return this.$store.getters['global/areFiltersClean']
    },
    isSidebarRightOpened () {
      return this.$store.getters['global/isSidebarRightOpened']
    },
    isCurrentWorldOwner () {
      return this.$store.getters['auth/isWorldOwner'];
    },
    contentFormatted () {
      return this.formatContent()
    },
    contentFiltered () {
      if (!this.filters) {
        return []
      }
      if (this.searchValue === '') {
        return this.applyDataFilters(this.contentFormatted, this.filters)
      }

      return new Fuse(this.applyDataFilters(this.contentFormatted, this.filters), {
        shouldSort: false,
        threshold: 0.3,
        location: 0,
        distance: 100,
        minMatchCharLength: 1,
        keys: [
          "data.name",
          "data.description",
        ]
      }).search(this.searchValue)
    },
    searchValue: {
      get () {
        return this.$store.state.global.sidebarSearch
      },
      set (value) {
        this.$store.commit('global/changeSidebarSearch', value)
      }
    }
  },
  watch: {
    searchValue(val) {
      this.$nextTick(() => {
        this.$refs.filterSearch.$refs.filterSearchInput.focus()
      })
    },
    areFiltersClean(val) {
      let renderSidebar = !val
      this.changeSidebarRight(renderSidebar)
    }
  }
}
</script>

<style lang="scss" scoped>
.onboarding-card {
  min-height: 100%;
}
.cat-picto {
  width: 100px;
  height: auto;
}
.onboarding-name {
  border-bottom-width: 3px !important;
}
.summary {
  > div {
    position: relative;
    padding-left: 20px;
    &:before {
      position: absolute;
      width: 2px;
      background: #ccc;
      left: 3px;
      top: 30px;
      bottom: -27px;
      content: '';
      display: block;
    }
    &:last-child {
      &:before {
        display: none;
      }
    }
    > i {
      position: absolute;
      left: -4px;
      top: 15px;
      &.icon-checkmark-circle {
        transform: scale(1.3);
      }
    }
  }
}
.colorfield {
  cursor: pointer;
  width: 19px;
  height: 19px;
  margin: 0 0 2px 4px;
  border-radius: 100px;
  display: inline-block;
  vertical-align: middle;
  &.active {
    border: 3px solid white;
    box-shadow: 0 0 0 2px black;
  }
}
</style>
